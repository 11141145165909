import React from "react";
import GetMenuProducts from "./GetMenuProducts";
import "../../../styles/MenuProducts.css";

function MainDishes() {
  return (
    <div className="menu__categories">
      <div>
        <h3>Platillos - Dishes</h3>
        <h5>Served with rice, beans, and salad</h5>
        <GetMenuProducts productList={"DishesWSaladV2"} />
      </div>
      <div>
        <h3>Platillos - Dishes</h3>
        <h5>Served with rice and beans</h5>
        <GetMenuProducts productList={"DishesRBV2"} />
      </div>
    </div>
  );
}

export default MainDishes;
