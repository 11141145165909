import React from "react";
import GetMenuProducts from "./GetMenuProducts";
import "../../../styles/MenuProducts.css";

function Sides() {
  const currentTimeDate = new Date();
  const checkDay = (day) => {
    if (currentTimeDate.getDay() !== day) {
      return true;
    }
  };
  return (
    <div className="menu__categories">
      <div>
        <h3>Daily Specials</h3>
        <GetMenuProducts productList={"MondayV2"} disabled={checkDay(1)} />
        <GetMenuProducts productList={"TuesdayV2"} disabled={checkDay(2)} />
        <GetMenuProducts productList={"WednesdayV2"} disabled={checkDay(3)} />
        <GetMenuProducts productList={"ThursdayV2"} disabled={checkDay(4)} />
        <GetMenuProducts productList={"FridayV2"} disabled={checkDay(5)} />
      </div>
    </div>
  );
}

export default Sides;
