import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import ChecboxOptions from "./CheckboxOptions";
import { addToCartV2 } from "../../../store/actions/actions";
import "../../../styles/Card.css";

export default function ScrollDialog(props) {
  const ingredientList = useSelector((state) => state.ingredientList);
  const [checkedIngredients, setCheckedIngredients] = React.useState([]);
  const [specialInstructions, setSpecialInstructions] = React.useState([""]);
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [scroll, setScroll] = React.useState("paper");
  const [openSnackBar, setOpenSnackBar] = React.useState(true);

  const dispatch = useDispatch();

  const addIngredientHandler = (event) => {
    const isChecked = event.target.checked;
    const ingredientId = event.target.value;
    const ingredientListState = checkedIngredients;

    const ingredientIdExists = checkedIngredients.find(
      (currentIngredientId) => currentIngredientId === ingredientId
    );
    if (isChecked) {
      if (!ingredientIdExists) {
        ingredientListState.push(ingredientId);
      }
    } else {
      if (ingredientIdExists) {
        ingredientListState.splice(
          ingredientListState.indexOf(ingredientIdExists),
          1
        );
      }
    }
    setCheckedIngredients(checkedIngredients);
  };

  const addSpecialInstructionHandler = (event) => {
    const changedValue = event.target.value;
    const valueArrary = [];
    valueArrary.push(changedValue);
    setSpecialInstructions(valueArrary);
  };

  const updateCartHandler = (dishId) => {
    dispatch(addToCartV2(dishId, checkedIngredients, specialInstructions));
    // setSuccess(true);
    props.handleDialogOpen();
    // showSuccess(true);
    props.showSuccess();
    // console.log("in here", success);
  };

  const ingredientCheckboxList = ingredientList.filter((dt) => {
    for (let i = 0; i < props.parentIngredients.length; i++) {
      if (dt._id === props.parentIngredients[i]) {
        return true;
      }
    }
    return null;
  });

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // eslint-disable-next-line
  const showSuccess = (success) => {
    console.log("in success", success);
    return (
      <div style={{ display: success ? "" : "none" }}>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Added To Cart
          </Alert>
        </Snackbar>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleDialogOpen}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.parentName}</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <ChecboxOptions
            addIngredientHandler={addIngredientHandler}
            addSpecialInstructionHandler={addSpecialInstructionHandler}
            ingredientCheckboxList={ingredientCheckboxList}
            specialInstructions={specialInstructions}
          />
        </DialogContent>
        <DialogActions className="cardDialog__addToCart">
          <Button
            disabled={props.disabled}
            onClick={() => updateCartHandler(props.parentId)}
          >
            Add To Cart
          </Button>
        </DialogActions>
      </Dialog>
      {/* {showSuccess(success)} */}
    </div>
  );
}
