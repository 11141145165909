import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { isAuthenticated } from "../../auth/index";
import {
  createProduct,
  getCategories,
  getIngredients,
} from "../../components/Menu/Api/menuApi";
import ProductHelpers from "./ProductHelpers";

function CreateProducts() {
  const [values, setValues] = useState({
    name: "",
    description: "",
    price: "",
    categories: [],
    category: "",
    quantity: "",
    photo: "",
    loading: false,
    error: "",
    createdProduct: "",
    redirectToProfile: false,
    ingredients: [],
    ingredient: [],
    formData: "",
  });

  const { user, token } = isAuthenticated();
  const {
    // name,
    // description,
    // price,
    // categories,
    // quantity,
    loading,
    error,
    createdProduct,
    // ingredient,
    formData,
  } = values;

  const init = () => {
    getCategories().then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({ ...values, categories: data, formData: new FormData() });
        initIngredients();
      }
    });
  };

  const initIngredients = () => {
    getIngredients().then((data) => {
      if (data.error) {
        setValues((values) => {
          return { ...values, error: data.error };
        });
      } else {
        setValues((values) => {
          return { ...values, ingredient: data };
        });
      }
    });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });
    createProduct(user._id, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          name: "",
          description: "",
          photo: "",
          price: "",
          quantity: "",
          ingredients: "",
          loading: false,
          createdProduct: data.name,
        });
      }
    });
  };

  const showError = () => (
    <div style={{ display: error ? "" : "none" }}>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    </div>
  );

  const showSuccess = () => (
    <div style={{ display: createdProduct ? "" : "none" }}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {`${createdProduct}`} <strong>is created!</strong>
      </Alert>
    </div>
  );

  const showLoading = () =>
    loading && (
      <div>
        <Alert severity="info">
          <AlertTitle>Loading...</AlertTitle>
        </Alert>
      </div>
    );

  return (
    <div>
      {showLoading()}
      {showSuccess()}
      {showError()}
      <ProductHelpers
        name={values.name}
        description={values.description}
        price={values.price}
        quantity={values.quantity}
        categories={values.categories}
        ingredient={values.ingredient}
        values={values}
        setValues={setValues}
        formData={values.formData}
        clickSubmit={clickSubmit}
      />
    </div>
  );
}

export default CreateProducts;
