import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import { isAuthenticated } from "../../auth/index";
import { Link } from "react-router-dom";
import {
  updateCategories,
  getCategories,
} from "../../components/Menu/Api/menuApi";
import "../../styles/UpdateCategory.css";

function UpdateCategories() {
  const [name, setName] = useState("");
  const [values, setValues] = useState({
    // name: "",
    // description: "",
    // price: "",
    categories: [],
    category: "",
    // products: [],
    // product: '',
    // // shipping: '',
    // quantity: "",
    // photo: "",
    // loading: false,
    // error: "",
    // createdProduct: "",
    // redirectToProfile: false,
    formData: "",
  });

  const {
    // name,
    // description,
    // price,
    categories,
    // category,
    // products,
    // product,
    // shipping,
    // quantity,
    // loading,
    // error,
    // createdProduct,
    // redirectToProfile,
    // formData,
  } = values;
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // destructure user and token from localstorage
  const { user, token } = isAuthenticated();

  const initCategories = () => {
    getCategories().then((data) => {
      if (data.error) {
        setValues((values) => {
          return { ...values, error: data.error };
        });
      } else {
        setValues((values) => {
          return { ...values, categories: data, formData: new FormData() };
        });
      }
    });
  };

  useEffect(() => {
    initCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = (e) => {
    setError("");
    setValues({ ...values, category: e.target.value });
    console.log(e.target.value);
  };

  const handleChange = (e) => {
    setError("");
    setName(e.target.value);
    console.log("category", values.category);
  };

  const clickSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    // make request to api to create category
    updateCategories(user._id, token, values.category, { name }).then(
      (data) => {
        if (data.error) {
          setError(true);
        } else {
          setError("");
          setSuccess(true);
        }
        console.log(data);
      }
    );
  };

  const newCategoryForm = () => (
    <form className="updateCategory__form" onSubmit={clickSubmit}>
      <div className="updateCategory__inputDropdown">
        <label className="text-muted">Category</label>
        <select onChange={handleCategoryChange} className="form-control">
          {/* <select onChange={handleChange("category")} className="form-control"> */}
          <option>Please select</option>
          {categories &&
            categories.map((c, i) => (
              <option key={i} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>
      <div className="updateCategory__input">
        <TextField
          label="Name"
          type="text"
          onChange={handleChange}
          value={name}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      {/* <div className="form-group">
        <label className="text-muted">Name</label>
        <input
          type="text"
          className="form-control"
          onChange={handleChange}
          value={name}
          autoFocus
          required
        />
      </div> */}
      <button className="updateCategory__button">Update Category</button>
    </form>
  );

  const showError = () => (
    <div style={{ display: error ? "" : "none" }}>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    </div>
  );

  const showSuccess = () => (
    <div style={{ display: success ? "" : "none" }}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {`${name}`} <strong>is created!</strong>
      </Alert>
    </div>
  );

  const goBack = () => (
    <div className="updateCategory__backButton">
      <Link to="/admin/dashboard">
        Back to Dashboard
      </Link>
    </div>
  );

  return (
    <div className="updateCategory">
      {showSuccess()}
      {showError()}
      {newCategoryForm()}
      {goBack()}
    </div>
  );
}

export default UpdateCategories;
