import React from "react";
import GetMenuProducts from "./GetMenuProducts";
import "../../../styles/MenuProducts.css";

function Breakfast() {
  return (
    <div className="menu__categories">
      <div>
        <h3>Breakfast</h3>
        <GetMenuProducts productList={"BreakfastV2"} />
      </div>
      <div>
        <h3>Breakfast Burritos</h3>
        <GetMenuProducts productList={"BreakfastBurritosV2"} />
      </div>
      <div>
        <h3>Breakfast Tortas</h3>
        <GetMenuProducts productList={"BreakfastTortasV2"} />
      </div>
    </div>
  );
}

export default Breakfast;
