import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AlaCarte from "../Menu/MenuItems/AlaCarte";
import Breakfast from "../Menu/MenuItems/Breakfast";
import Burgers from "../Menu/MenuItems/Burgers"
import Drinks from "../Menu/MenuItems/Drinks";
import Kids from "../Menu/MenuItems/Kids";
import MainDishes from "../Menu/MenuItems/MainDishes";
import Seafood from "../Menu/MenuItems/Seafood";
import Sides from "../Menu/MenuItems/Sides";
import Soups from "../Menu/MenuItems/Soups";
import Special from "../Menu/MenuItems/Special";

function TabPanel(props, { tabNames }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ScrollableTabsButtonAuto({ tabNames }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ bgcolor: "background.paper" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabNames.map((name, index) => (
            <Tab label={name} key={index} />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AlaCarte />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Breakfast />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Burgers />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Drinks />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Kids />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <MainDishes />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Seafood />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Sides />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Soups />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Special />
      </TabPanel>
    </div>
  );
}
