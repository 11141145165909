import React from "react";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import AppleStore from "../../assets/img/downloadAppStore.svg";
import "../../styles/Footer.css";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer__center">
        <div className="footer__left">
          <h1>Contact</h1>
          <a href="https://www.google.com/maps/place/Taqueria+Las+Cumbres/@42.2433374,-88.3206829,17z/data=!3m1!4b1!4m5!3m4!1s0x880f72a206ffece1:0x30e904ac2211e49a!8m2!3d42.2433334!4d-88.3184942">
            93 Grant St, Crystal Lake, IL 60014
          </a>
          <a href="tel:815-455-8200">815-455-8200</a>
        </div>
        <div className="footer__right">
          <h1>Restaurant Hours</h1>
          <div className="footer__hours">
            <div className="footer__hpursLeft">
              <p>Sunday-Thrusday:</p>
              <p>Friday-Saturday:</p>
            </div>
            <div className="footer__hoursRight">
              <p>10:00am-9:00pm</p>
              <p>10:00am-10:00pm</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="footer__socialMedia">
          <Button href="https://apple.co/44T7VV9" target="_blank">
            <img src={AppleStore} alt="" className="footer__appStore" />
          </Button>
          <Button
            href="https://www.facebook.com/TaqueriaLasCumbres"
            target="_blank"
          >
            <FacebookIcon aria-label="facebook" />
          </Button>
          <Button
            href="https://www.instagram.com/taquerialascumbres"
            target="_blank"
          >
            <InstagramIcon />
          </Button>
        </div>
        <p>Copyright ⓒ {year} Taqueria Las Cumbres</p>
      </div>
    </div>
  );
}

export default Footer;
