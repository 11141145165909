import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "../styles/Jobs.css";

function Jobs() {
  return (
    <div>
      <Header />
      <div className="jobs">
        <h1>Careers</h1>
        <div className="jobs__positions">
          <div className="jobs__positionApply">
            <h3>Restaurant Team Member - Cook / Cocinero/a</h3>
            <Link to="/apply/Kitchen">
              <button className="jobs__buttonLinks">Apply</button>
            </Link>
          </div>
          <div className="jobs__positionApply">
            <h3>Restaurant Team Member - Server / Mesero/a</h3>
            <Link to="/apply/Server">
              <button className="jobs__buttonLinks">Apply</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Jobs;
