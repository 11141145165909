import React, { useState } from "react";
import ServerEnglish from "./English/ServerEnglish";
import ServerSpanish from "./Spanish/ServerSpanish";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "../../styles/Jobs.css";

function Server() {
  const options = ["English", "Spanish"];
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const defaultOption = options[0];

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.value);
  };

  return (
    <div>
      <div className="jobs__dropdown">
        <Dropdown
          options={options}
          onChange={(e) => handleLanguageChange(e)}
          value={defaultOption}
          placeholder="Select an option"
        />
      </div>
      <div>
        {selectedLanguage === "Spanish" && <ServerSpanish />}
        {selectedLanguage === "English" && <ServerEnglish />}
      </div>
    </div>
  );
}

export default Server;
