import React from "react";
import Button from "@mui/material/Button";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MenuOptions from "../components/ScrollableTabs/MenuOptions";
import AppStore from "../assets/img/appleStore.png";
import "../styles/Menu.css";

function Menu() {
  return (
    <div className="menu">
      <Header />
      <div className="menu__topSection">
        <div className="menu__topText">
          <Button href="https://apple.co/44T7VV9" target="_blank">
            <img
              src={AppStore}
              alt="App Store download"
              className="menu__appStoreLogo"
            />
          </Button>
          <p>Only checkbox options may be added</p>
          <p>If item is out of stock, item will be refunded</p>
        </div>
      </div>
      <MenuOptions />
      <Footer />
    </div>
  );
}

export default Menu;
