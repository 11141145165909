import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import { isAuthenticated } from "../../auth/index";
import { createIngredient } from "../../components/Menu/Api/menuApi";
import "../../styles/CreateIngredients.css";

function CreateIngredients() {
  const [values, setValues] = useState({
    name: "",
    ref: "",
    price: "",
    loading: false,
    error: "",
    createdProduct: "",
    formData: "",
  });

  const { user, token } = isAuthenticated();
  const { name, ref, price, loading, error, createdProduct, formData } = values;

  const init = () => {
    setValues({ ...values, formData: new FormData() });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });

    createIngredient(user._id, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          name: "",
          ref: "",
          price: "",
          loading: false,
          createdProduct: data.name,
        });
      }
    });
  };

  const newPostForm = () => (
    <form className="createIngredients__form" onSubmit={clickSubmit}>
      <div className="createIngredients__input">
        <TextField
          label="Name"
          type="text"
          onChange={handleChange("name")}
          value={name}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      <div className="createIngredients__input createIngredients__description">
        <TextField
          label="Description"
          type="text"
          onChange={handleChange("ref")}
          value={ref}
          autoFocus
          required
          fullWidth
          multiline
          variant="outlined"
        />
      </div>
      <div className="createIngredients__input">
        <TextField
          label="Price"
          type="number"
          onChange={handleChange("price")}
          value={price}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      <button className="createIngredients__button">Create Ingredient</button>
    </form>
  );

  const showError = () => (
    <div style={{ display: error ? "" : "none" }}>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    </div>
  );

  const showSuccess = () => (
    <div style={{ display: createdProduct ? "" : "none" }}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {`${createdProduct}`} <strong>is created!</strong>
      </Alert>
    </div>
  );

  const showLoading = () =>
    loading && (
      <div>
        <Alert severity="info">
          <AlertTitle>Loading...</AlertTitle>
        </Alert>
      </div>
    );

  return (
    <div className="createIngredients">
      {showLoading()}
      {showSuccess()}
      {showError()}
      {newPostForm()}
    </div>
  );
}

export default CreateIngredients;
