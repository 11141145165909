import React from "react";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { isAuthenticated } from "../auth/index";
import Header from "../components/Header/Header";
import "../styles/Profile.css";

function Profile() {
  const orderHistory = useSelector(state => state.currentOrderHistory)
  let isAuth = isAuthenticated();

  const redirectUser = () => {
    if (!isAuth) {
      return <Navigate to="/signin" replace />;
    }
  };

  return (
    <div className="profile">
      <Header />
      <h1>Profile</h1>
      {orderHistory.map((order, index) => {
        return (
          <div key={index} className="profile__eachOrder">
            <h1>{`Order #${orderHistory.length - index}`}</h1>
            <li>Ordered {moment(order.createdAt).fromNow()}</li>
            <li>{`Total $${order.amount} - ${order.products.length} items`}</li>
            {/* <li>Status: {order.status}</li> */}
            <div className="profile__viewOrderButton">
              <Link to={`/profile/order/${orderHistory.length - index}`} state={order}>
                <button >View Order</button>
                </Link>
            </div>
          </div>
        );
      })}
    {redirectUser()}
    </div>
  );
}

export default Profile;
