import React from "react";
import GetMenuProducts from "./GetMenuProducts";
import "../../../styles/MenuProducts.css";

function AlaCarte() {
  return (
    <div className="menu__categories">
      <div>
        <h3>Burritos</h3>
        <GetMenuProducts productList={"BurritosV2"} />
      </div>
      <div>
        <h3>Tacos</h3>
        <GetMenuProducts productList={"TacosV2"} />
      </div>
      <div>
        <h3>Gorditas</h3>
        <GetMenuProducts productList={"GorditasV2"} />
      </div>
      <div>
        <h3>Tostadas</h3>
        <GetMenuProducts productList={"TostadasV2"} />
      </div>
      <div>
        <h3>Quesadillas</h3>
        <GetMenuProducts productList={"QuesadillasV2"} />
      </div>
      <div>
        <h3>Tortas</h3>
        <GetMenuProducts productList={"TortasV2"} />
      </div>
      <div>
        <h3>Other House Specialties</h3>
        <GetMenuProducts productList={"HouseSpecialtiesV2"} />
      </div>
    </div>
  );
}

export default AlaCarte;
