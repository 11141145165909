import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Header from "../components/Header/Header";
import { addToCartV2 } from "../store/actions/actions";
import "../styles/Cart.css";

function IndividualOrder() {
  const dispatch = useDispatch();
  const { orderNumber } = useParams();
  const [success, setSuccess] = useState(false);
  const currentOrderInfo = useSelector((state) => state.currentOrderHistory);
  const currentOrderDetails =
    currentOrderInfo[currentOrderInfo.length - orderNumber];
  const totalAmount = currentOrderDetails?.amount;
  const taxAmount = currentOrderDetails?.taxAmount;
  const tipAmount = currentOrderDetails?.tipAmount;

  const addToCartHandler = (
    dishId,
    checkedIngredients,
    specialInstructions
  ) => {
    let addedIngredients = [];
    let specialInstructionArray = [];
    if (!specialInstructions) {
      specialInstructions = "";
    } else if (specialInstructions) {
      specialInstructionArray.push(specialInstructions);
    }
    if (checkedIngredients === null) {
      checkedIngredients = [];
    } else {
      checkedIngredients.map((ingredientInfo) => {
        return addedIngredients.push(ingredientInfo._id);
      });
    }
    dispatch(addToCartV2(dishId, addedIngredients, specialInstructionArray));
    setSuccess(true);
  };

  const getSubtotal = () => {
    return parseFloat(totalAmount - taxAmount - tipAmount).toFixed(2);
  };

  const showSuccess = (success) => {
    return (
      <div style={{ display: success ? "" : "none" }}>
        <Alert onClose={() => {setSuccess(false)}} severity="success" className="alert__success">
          <AlertTitle>Success</AlertTitle>
          Added to cart
        </Alert>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <div className="individual__order">
        <h1>You have ordered:</h1>
        {currentOrderDetails?.products.length > 0 ? (
          currentOrderDetails?.products.map((order, index) => {
            return (
              <li key={index} className="cart__item">
                <span>
                  <b>{order.dish[0].name}</b>
                </span>
                <p>
                  <b>
                    {order.ingredients.length === 0
                      ? null
                      : "Added Ingredients: " +
                        order.ingredients
                          .map((ingredient) => ingredient.name)
                          .join(", ")}
                  </b>
                </p>
                <p>
                  <b>Item Price: ${order.total}</b>
                </p>
                <p>
                  <b>
                    {order.specialInstruction.length === 0
                      ? null
                      : "Special Instructions: " + order.specialInstruction}
                  </b>
                </p>
                <div>
                  <button
                    className="individualOrder__reorder"
                    onClick={() =>
                      addToCartHandler(
                        order.dish[0]._id,
                        order.ingredients,
                        order.specialInstruction
                      )
                    }
                  >
                    Reorder
                  </button>
                </div>
              </li>
            );
          })
        ) : (
          <p className="cart">Cannot find order</p>
        )}
      </div>
      <div className="individualOrder__amountInfo">
        <li>
          <b>SubTotal: ${getSubtotal()}</b>
        </li>
        <li>
          <b>Tax: ${parseFloat(taxAmount)} </b>
        </li>
        {tipAmount && (
          <li>
            <b>Tip: ${parseFloat(tipAmount)}</b>
          </li>
        )}
        <li>
          <b>Total: ${parseFloat(totalAmount).toFixed(2)}</b>
        </li>
      </div>
      {showSuccess(success)}
    </div>
  );
}

export default IndividualOrder;
