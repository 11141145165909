import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import "../../styles/AdminDashboard.css";

function AdminDashboard() {
  return (
    <div className="adminDashboard">
      <Link to="/">
        <Button>Home</Button>
      </Link>
      <Link to="/admin/kitchenOrders">
        <Button>Kitchen Orders</Button>
      </Link>
      <Link to="/admin/serverOrders">
        <Button>Server Orders</Button>
      </Link>
      <Link to="/admin/todaysOrders">
        <Button>Todays Orders</Button>
      </Link>
      <Link to="/admin/adjustMenu">
        <Button>Adjust Menu</Button>
      </Link>
    </div>
  );
}

export default AdminDashboard;
