import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import "../../styles/AdminDashboard.css";

function AdjustMenu() {
  return (
    <div className="adminDashboard">
      <Link to="/">
        <Button>Home</Button>
      </Link>
      <Link to="/admin/dashboard">
        <Button>Admin Dashboard</Button>
      </Link>
      <Link to="/admin/createCategory">
        <Button>Create Categories</Button>
      </Link>
      <Link to="/admin/createProducts">
        <Button>Create Products</Button>
      </Link>
      <Link to="/admin/createIngredient">
        <Button>Create Ingredients</Button>
      </Link>
      <Link to="/admin/updateCategory">
        <Button>Update Categories</Button>
      </Link>
    </div>
  );
}

export default AdjustMenu;
