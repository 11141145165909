import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import Footer from "../Footer/Footer";
import Stripe from "./Stripe";
import { isAuthenticated } from "../../auth/index";
import { createOrder, emptyCart } from "../Menu/Api/menuApi";
import { clearCart } from "../../store/actions/actions";
import "../../styles/Checkout.css";

function Checkout() {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    loading: false,
    success: false,
    clientToken: null,
    error: "",
    instance: {},
    name: "",
    phone: "",
    email: "",
    address: "",
    tipAmount: "",
  });

  const [inputTip, setInputTip] = useState(0);

  let totalAmount = useSelector((state) => state.orderTotal);
  let orderDetails = useSelector((state) => state.orderList);

  const userId = isAuthenticated() && isAuthenticated().user._id;
  const token = isAuthenticated() && isAuthenticated().token;
  var currentTimeDate = new Date();

  const handleEmail = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handleNumber = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleName = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const getTip = () => {
    let adjustedTip = inputTip;
    if (!adjustedTip) {
      adjustedTip = 0;
    }
    return parseFloat(adjustedTip).toFixed(2);
  };

  const getTotal = () => {
    let newAmount = parseFloat(totalAmount * 1.0775).toFixed(2);
    let adjustedTip = getTip();
    return parseFloat(newAmount) + parseFloat(adjustedTip);
  };

  const handleTip = (e) => {
    let newAmount = e.target.value;
    if (newAmount) {
      newAmount = Math.abs(newAmount);
    }
    setData({ ...data, tipAmount: newAmount });
    setInputTip(newAmount);
  };

  const showCheckout = () => {
    return isAuthenticated() ? (
      <div>{showDropIn()}</div>
    ) : (
      <div>
        <div className="checkout__signin">
          <Link to="/signin">
            <button>Sign in to checkout</button>
          </Link>
        </div>
        <div className="checkout__footerBottom">
          <Footer />
        </div>
      </div>
    );
  };

  const disableButton = () => {
    // if(currentTimeDate.getDay() === 0 || (currentTimeDate.getHours() < 10 || currentTimeDate.getHours() >= 20)) {
    if (currentTimeDate.getHours() < 10 || currentTimeDate.getHours() >= 20) {
      return false;
    }
    if (data.name && data.phone && data.email) {
      return true;
    }
  };

  const setSuccess = () => {
    setData({ ...data, success: true });
  };

  const showSuccess = (success) => (
    <div style={{ display: success ? "" : "none" }}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Thanks! Your payment was successful! Check your email for receipt and
        pickup instructions! If you have not received anything within 5 minutes,
        call us!
      </Alert>
    </div>
  );

  let fullName = data.name;
  let phoneNumber = data.phone;
  let emailAddress = data.email;
  let addedTipAmount = data.tipAmount;
  let taxAmount = parseFloat(totalAmount * 0.0775).toFixed(2);

  const handleCash = () => {
    const createOrderData = {
      products: orderDetails,
      amount: getTotal(),
      name: fullName,
      phone: phoneNumber,
      email: emailAddress,
      tipAmount: addedTipAmount,
      taxAmount: taxAmount,
    };
    // setData({loading: true})
    createOrder(userId, token, createOrderData)
      .then((response) => {
        emptyCart(() => {
          // setData({ loading: false })
          setSuccess();
          dispatch(clearCart());
        });
      })
      .catch((error) => {
        setData({ error: error.message });
      });
  };

  const showDropIn = () => (
    <div onBlur={() => setData({ ...data, error: "" })}>
      {orderDetails.length > 0 ? (
        <div>
          <div className="checkout__form">
            <li>
              <b>SubTotal: ${parseFloat(totalAmount).toFixed(2)}</b>
            </li>
            <li>
              <b>Tax: ${parseFloat(totalAmount * 0.0775).toFixed(2)} </b>
            </li>
            <li>
              <b>Tip: ${getTip()}</b>
            </li>
            <li>
              <b>Total: ${getTotal().toFixed(2)}</b>
            </li>
            <div className="checkout__inputForm">
              <form>
                {/* <fieldset className="FormGroup"> */}
                <div>
                  <TextField
                    label="Tip Amount"
                    type="number"
                    onChange={handleTip}
                    value={data.tipAmount || ""}
                    min="0"
                    autoFocus
                    required
                    fullWidth
                    variant="standard"
                  />
                </div>
                <div>
                  <TextField
                    label="Name"
                    type="text"
                    onChange={handleName}
                    value={data.name}
                    autoFocus
                    required
                    fullWidth
                    variant="standard"
                  />
                </div>
                <div>
                  <TextField
                    label="Email"
                    type="email"
                    onChange={handleEmail}
                    value={data.email}
                    autoFocus
                    required
                    fullWidth
                    variant="standard"
                  />
                </div>
                <div>
                  <TextField
                    label="Phone Number"
                    type="tel"
                    onChange={handleNumber}
                    value={data.phone}
                    autoFocus
                    required
                    fullWidth
                    variant="standard"
                  />
                </div>
                {/* </fieldset> */}
              </form>
            </div>
            <Stripe
              totalAmount={getTotal()}
              fullName={fullName}
              phoneNumber={phoneNumber}
              emailAddress={emailAddress}
              addedTipAmount={addedTipAmount}
              taxAmount={taxAmount}
              userId={userId}
              token={token}
              disabled={disableButton()}
              setSuccess={setSuccess}
            />
            <div style={{ width: "100%", margin: "20px" }}>
              {/* {((currentTimeDate.getDay() === 0) || (currentTimeDate.getHours() < 10) || (currentTimeDate.getHours() >= 20)) ? <p className="alert alert-danger">Currently Closed. Please Come back during business hours.</p>: ''} */}
              {currentTimeDate.getHours() < 10 ||
              currentTimeDate.getHours() >= 20 ? (
                <p className="alert alert-danger">
                  Currently Closed. Please Come back during business hours.
                </p>
              ) : (
                ""
              )}
            </div>
            <div style={{ width: "100%", margin: "20px" }}>
              {isAuthenticated() && isAuthenticated().user.role === 1 && (
                <button className="cashButton" onClick={handleCash}>
                  Cash
                </button>
              )}
            </div>
          </div>
          <div className="footerCartDropIn">
            <Footer />
          </div>
        </div>
      ) : (
        <div className="checkout__footerBottom">
          <Footer />
        </div>
      )}
    </div>
  );

  const showError = (error) => (
    <div style={{ display: error ? "" : "none" }}>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    </div>
  );

  const showLoading = (loading) => loading && <h2>Loading...</h2>;

  return (
    <div className="checkout">
      {showLoading(data.loading)}
      {showSuccess(data.success)}
      {showError(data.error)}
      {showCheckout()}
    </div>
  );
}

export default Checkout;
