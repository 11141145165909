import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/img/logo.png";
import { isAuthenticated, signout } from "../../auth/index";
import "../../styles/Header.css";

const pages = [
  { name: "Home", link: "/" },
  { name: "Menu", link: "/menu" },
  { name: "Cart", link: "/cart" },
  { name: "Jobs", link: "/jobs" },
];
const adminPages = [
  { name: "Home", link: "/" },
  { name: "Menu", link: "/menu" },
  { name: "Cart", link: "/cart" },
  { name: "Admin", link: "/admin/dashboard" },
];
const loggedOutSettings = [
  { name: "Profile", link: "/profile" },
  { name: "Login", link: "/signin" },
];

function ResponsiveAppBar() {
  const isAuth = isAuthenticated();
  const isAdmin = isAuthenticated() && isAuthenticated().user.role === 1;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" className="header">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={"/"} className="header__logo">
            <img alt="" src={Logo} />
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {isAdmin &&
                adminPages.map((page, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      <Link to={page.link}>{page.name}</Link>
                    </Typography>
                  </MenuItem>
                ))}
              {!isAdmin &&
                pages.map((page, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu} className="header__profileLinks">
                    <Typography textAlign="center">
                      <Link to={page.link}>{page.name}</Link>
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Link to={"/"} className="header__logoMobile">
            <img alt="" src={Logo} />
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {isAdmin &&
              adminPages.map((page, index) => (
                <Link to={page.link} key={index} className="header__profileLinks">
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page.name}
                  </Button>
                </Link>
              ))}
            {!isAdmin &&
              pages.map((page, index) => (
                <Link to={page.link} key={index} className="header__profileLinks">
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page.name}
                  </Button>
                </Link>
              ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={isAuth?.user?.name} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isAuth && (
                <MenuItem
                  onClick={handleCloseUserMenu}
                  className="header__profileLinks"
                >
                  <Link to="/profile">
                    <Button className="header__logout">
                      <Typography textAlign="center" className="header__blackText">Profile</Typography>
                    </Button>
                  </Link>
                  <Link to="/">
                    <Button
                      className="header__logout"
                      onClick={() =>
                        signout(() => {
                          handleCloseUserMenu();
                        })
                      }
                    >
                      <Typography textAlign="center" className="header__blackText">Logout</Typography>
                    </Button>
                  </Link>
                </MenuItem>
              )}
              {!isAuth &&
                loggedOutSettings.map((setting, index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu} className="header__blackText">
                    <Link to={setting.link}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </Link>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
