import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/Jobs.css";

function ServerSpanish() {
  return (
    <div className="application">
      <h3>Titulo: Mesero/a</h3>
      <h4>Descripción de trabajo</h4>
      <p>
        Estamos buscando mesero/a que se puede adaptar al trabajo!
        Trabajamos rápido y contamos con un lugar amigable
      </p>
      <h5>Responsabilidades:</h5>
      <ul>
        <li>
          • Dar servicio de calidad a cada cliente y mantener altos
          estándares de limpieza, actitud, producto, y servicio de la empresa
        </li>
        <li>• Cumplir o superar el servicio al cliente</li>
        <li>• Servir productos frescos de calidad</li>
        <li>• Mantener limpio</li>
        <li>• Seguir todo los procesos de seguridad (de comida y de personal)</li>
        <li>• Adaptarse al trabajo</li>
      </ul>
      <h5>Requisitos:</h5>
      <ul>
        <li>• Alto nivel de responsabilidad y ganas de trabajar arduamente</li>
        <li>• Deseo de crecimiento personal y profesional</li>
        <li>• Disfrutar trabajar en equipo</li>
        <li>• Disfrutar trabajar en un ambiente de ritmo rápido</li>
        <li>• Impulso y determinación</li>
      </ul>
      <h5>Por qué nosotros:</h5>
      <ul>
        <li>• Horarios flexibles</li>
        <li>• Ambiente amigable</li>
        <li>• Oportunidad de crecimiento</li>
      </ul>
      <div className="application__button">
        <Link to="/apply/application/SP/Mesero">
          <button>Aplicar</button>
        </Link>
      </div>
    </div>
  );
}

export default ServerSpanish;
