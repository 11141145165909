import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Header from "../components/Header/Header";
// import Footer from "../components/Footer/Footer";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  addQuantityV2,
  subtractQuantityV2,
  removeDishV2,
} from "../store/actions/actions";
import "../styles/Cart.css";
import Checkout from "../components/Checkout/Checkout";

function Cart() {
  const cartOrder = useSelector((state) => state.orderList);
  const dispatch = useDispatch();

  return (
    <div>
      <Header />
      <div className="cart__items">
        <h1>You have ordered:</h1>
        {cartOrder.length > 0 ? (
          cartOrder.map((order, index) => {
            return (
              <li key={index} className="cart__item">
                <span>
                  <b>{order.dish.name}</b>
                </span>
                <p>{order.dish.description}</p>
                <p>
                  <b>
                    {order.ingredients?.length === 0
                      ? null
                      : "Added Ingredients: " +
                        order.ingredients
                          .map((ingredient) => ingredient.name)
                          .join(", ")}
                  </b>
                </p>
                <p>
                  <b>
                    {order.specialInstruction?.length === 0
                      ? null
                      : "Special Instructions: " + order.specialInstruction}
                  </b>
                </p>
                <p>
                  <b>Item Price: ${order.total}</b>
                </p>
                <span>
                  <b className="cart__quantity">
                    Quantity:{" "}
                    <Link to="/cart" className="cart__button">
                      <i onClick={() => dispatch(subtractQuantityV2(order.id))}>
                        <RemoveIcon />
                      </i>
                    </Link>
                    <p>{order.quantity}</p>
                    <Link to="/cart" className="cart__button">
                      <i onClick={() => dispatch(addQuantityV2(order.id))}>
                        <AddIcon />
                      </i>
                    </Link>
                  </b>
                </span>
                <button className="cart__remove" onClick={() => dispatch(removeDishV2(order.id))}>
                  Remove
                </button>
              </li>
            );
          })
        ) : (
          <p className="cart">Your cart is empty</p>
        )}
      </div>
      <Checkout />
      {/* <div  style={{ position: "absolute", bottom: 0, backgroundColor: "white", width: "100%" }}> */}
      {/* <Footer/> */}
      {/* </div> */}
    </div>
  );
}

export default Cart;
