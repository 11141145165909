import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/Jobs.css";

function ServerEnglish() {
  return (
    <div className="application">
      <h3>Title: Server</h3>
      <h4>Job Description</h4>
      <p>
        We are looking for a hardworking server that can adapt to the work
        environment! We are fast paced and maintain a family friendly work
        environment.
      </p>
      <h5>Responsibilites:</h5>
      <ul>
        <li>
          • Provide quality service to each customer and maintain the company’s
          high standards of cleanliness, attitude, product, and service
        </li>
        <li>• Meet or exceed speed of service of service standards</li>
        <li>• Serve fresh quality products</li>
        <li>• Maintain clean</li>
        <li>• Follow all food and personal safety procedures</li>
        <li>• Adapt to work environment</li>
      </ul>
      <h5>Requirements:</h5>
      <ul>
        <li>• High level of responsibility and desire to work hard</li>
        <li>• A desire for personal and professional growth</li>
        <li>• Enjoy working with a team</li>
        <li>• Enjoy working in a fast-paced environment</li>
        <li>• Drive and determination</li>
      </ul>
      <h5>Why us:</h5>
      <ul>
        <li>• Flexible schedules</li>
        <li>• Friendly environment</li>
        <li>• Growth opportunity</li>
      </ul>
      <div className="application__button">
        <Link to="/apply/application/EN/Server">
          <button>Apply</button>
        </Link>
      </div>
    </div>
  );
}

export default ServerEnglish;
