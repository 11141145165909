import React, { useState } from "react";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import { isAuthenticated } from "../../auth/index";
import { createCategory } from "../../components/Menu/Api/menuApi";
import "../../styles/CreateCategory.css";

function CreateCategory() {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { user, token } = isAuthenticated();

  const handleChange = (e) => {
    setError("");
    setName(e.target.value);
  };

  const clickSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    createCategory(user._id, token, { name }).then((data) => {
      if (data.error) {
        setError(true);
      } else {
        setError("");
        setSuccess(true);
      }
    });
  };

  const newCategoryForm = () => (
    <form onSubmit={clickSubmit} className="createCategory__form">
      <div className="createCategory__formGroup">
        <TextField
          className="createCategory__input"
          label="Name"
          type="text"
          onChange={handleChange}
          value={name}
          autoFocus
          required
          variant="standard"
        />
      </div>
      <button className="createCategory__button">Create Category</button>
    </form>
  );

  const showSuccess = () => {
    if (success) {
      return (
        <div style={{ display: success ? "" : "none" }}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {`${name}`} <strong>is created!</strong>
          </Alert>
        </div>
      );
    }
  };

  const showError = () => {
    if (error) {
      return (
        <div style={{ display: error ? "" : "none" }}>
          <Alert severity="error">
            <AlertTitle>Category should be unique</AlertTitle>
          </Alert>
        </div>
      );
    }
  };

  const goBack = () => (
    <div className="createCategory__backButton">
      <Link to="/admin/dashboard">Back to Dashboard</Link>
    </div>
  );

  return (
    <div className="createCategory">
      {showSuccess()}
      {showError()}
      {newCategoryForm()}
      {goBack()}
    </div>
  );
}

export default CreateCategory;
