import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { resetPassword } from "../auth/index";
import "../styles/SignupForm.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        Taqueria Las Cumbres
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function ResetPassword() {
  const { token } = useParams();
  const [values, setValues] = useState({
    password: "",
    passwordVerify: "",
    error: "",
    loading: false,
    redirectToReferrer: false,
  });

  const {
    password,
    passwordVerify,
    error,
    loading,
    redirectToReferrer,
  } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const validatePasswordsMatch = (pass1, pass2) => {
    if (pass1 === pass2) {
      return true;
    } else {
      setValues({ error: "Passwords do not match" });
    }
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    let validatePasswords = validatePasswordsMatch(password, passwordVerify);
    if (validatePasswords) {
      resetPassword({ token, password }).then((data) => {
        console.log(data);
        if (data.error) {
          setValues({ ...values, error: data.error });
        } else {
          setValues({ ...values, password: "", passwordVerify: "" });
        }
      });
    } else setValues({ ...values, error: "passwords dont match" });
  };

  const signupForm = () => (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="signupForm">
        <Avatar className="signupForm__avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Choose a new password
        </Typography>
        <form className="signupForm__form" noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="New Password"
            name="password"
            autoFocus
            onChange={handleChange("password")}
            type="password"
            value={password}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="passwordVerify"
            label="Verify Password"
            name="passwordVerify"
            autoComplete="password"
            autoFocus
            onChange={handleChange("passwordVerify")}
            type="password"
            value={passwordVerify}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="signupForm__submit"
            onClick={clickSubmit}
          >
            Reset Passsword
          </Button>
          <Grid container>
            <Grid item>
              <Link to="/signin" variant="body2">
                {"Already have an account? Sign in"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );

  const showError = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  const redirectUser = () => {
    if (redirectToReferrer) {
      //   if (user && user.role === 1) {
      //     return <Navigate to="/" replace />;
      //   } else {
      //     return <Navigate to="/" replace />;
      //   }
    }
  };

  return (
    <div>
      {showLoading()}
      {showError()}
      {signupForm()}
      {redirectUser()}
    </div>
  );
}

export default ResetPassword;
