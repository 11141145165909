import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { isAuthenticated } from "../../auth/index";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IngredientOptions from "../Menu/IngredientOptions/IngredientOptions";
import { API } from "../../config";
import ComingSoon from "../../assets//img/coming-soon.jpg";
import "../../styles/Card.css";

function Card(props) {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);

  let handleDialogOpen = (item) => {
    setCurrentItem(item);
    setOpen(!open);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = ComingSoon;
  };

  const showSuccess = (success) => {
    setOpenSnackBar(!openSnackBar);
    console.log("in success", success);
    return (
      <div style={{ display: success ? "" : "none" }}>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Added To Cart
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  return (
    <div className="card">
      {props.MenuList.map((item, index) => {
        return (
          <div className="card__outer" key={index}>
            <div className="card__inner">
              <div className="card__imgContainer">
                <img
                  src={`${API}/${props.url}/photo/${item._id}`}
                  alt={item.name}
                  className="card__img"
                  onError={addDefaultSrc}
                />
              </div>
              <div className="card__belowImage">
                <div className="card__name">
                  <b>{item.name}</b>
                </div>
                <div className="card__description">{item.description}</div>
                <div className="card__price">
                  <b>Price: ${item.price.toFixed(2)}</b>
                </div>
                <div className="card__addToCart">
                  {isAuthenticated() && isAuthenticated().user.role === 1 && (
                    <div
                      style={{
                        bottom: "20px",
                        position: "absolute",
                        textAlign: "center",
                        left: "20px",
                      }}
                    >
                      <Link
                        params={{ productId: item._id }}
                        to={{ pathname: `/admin/updateProduct/${item._id}` }}
                      >
                        <button>Update</button>
                      </Link>
                    </div>
                  )}
                  <button
                    disabled={props.disabled}
                    onClick={() => handleDialogOpen(item)}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
            {/* {showSuccess(success)} */}
          </div>
        );
      })}
      {open ? (
        <IngredientOptions
          open={open}
          disabled={props.disabled}
          handleDialogOpen={handleDialogOpen}
          parentPrice={currentItem.price}
          parentName={currentItem.name}
          parentImage={currentItem.img}
          parentId={currentItem._id}
          parentIngredients={currentItem.ingredients}
          productList={props.MenuList}
          showSuccess={showSuccess}
        />
      ) : null}
    </div>
  );
}

export default Card;
