// import React from "react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { isAuthenticated } from "../../auth/index";
import {
  updateProducts,
  getCategories,
  read,
  getIngredients,
} from "../../components/Menu/Api/menuApi";
import "../../styles/CreateProducts.css";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const UpdateProduct = ({ match }, props) => {
  const [values, setValues] = useState({
    name: "",
    description: "",
    price: "",
    categories: [],
    category: "",
    quantity: "",
    photo: "",
    loading: false,
    error: "",
    createdProduct: "",
    ingredients: [],
    ingredient: [],
    redirectToProfile: false,
    formData: "",
  });

  const { user, token } = isAuthenticated();
  const { productId } = useParams();
  const {
    name,
    description,
    price,
    categories,
    quantity,
    loading,
    error,
    createdProduct,
    ingredient,
    formData,
  } = values;

  const [addedIngredients, setAddedIngredients] = useState([]);

  const init = (productId) => {
    read(productId).then((data) => {
      if (data.error) {
        setValues((values) => {
          return { ...values, error: data.error };
        });
      } else {
        // console.log("props", data)
        setValues({
          ...values,
          name: data.name,
          description: data.description,
          price: data.price,
          quantity: data.quantity,
          category: data.category.name,
          ingredients: data.ingredients,
          formData: new FormData(),
        });
        setAddedIngredients(data.ingredients);
        initCategories();
        initIngredients();
      }
    });
  };

  //load categories and set form data
  const initCategories = () => {
    getCategories().then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues((values) => {
          return { ...values, categories: data, formData: new FormData() };
        });
        // setValues({  categories: data, formData: new FormData() });
        initIngredients();
      }
    });
  };

  const initIngredients = () => {
    getIngredients().then((data) => {
      if (data.error) {
        setValues((values) => {
          return { ...values, error: data.error };
        });
      } else {
        setValues((values) => {
          return { ...values, ingredient: data };
        });
      }
    });
  };

  useEffect(() => {
    init(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (name) => (event) => {
    const value = name === "photo" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const handleIngredientChange = (e) => {
    e.preventDefault();
    formData.set("ingredients", [...addedIngredients, e.target.value]);
    setAddedIngredients([...addedIngredients, e.target.value]);
  };

  const deleteIngredientHandler = (e, index) => {
    e.preventDefault();
    const myCurrentIngredients = [...addedIngredients];
    myCurrentIngredients.splice(index, 1);
    formData.set("ingredients", myCurrentIngredients);
    setAddedIngredients(myCurrentIngredients);
  };

  async function compressImage(photo) {
    // e.preventDefault();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 400,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(photo, options);
      // console.log("compressed", compressedFile)
      var file = new File([compressedFile], compressedFile.name, {
        type: compressedFile.type,
      });
      setValues({ ...values, photo: file });
      formData.set("photo", file);
    } catch (err) {
      console.log(err);
    }
  }

  async function clickSubmit(event) {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });
    if (values.photo !== "" && values.photo.size >= 1000000) {
      await compressImage(values.photo);
    }
    updateProducts(productId, user._id, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          name: "",
          description: "",
          photo: "",
          price: "",
          quantity: "",
          ingredients: "",
          loading: false,
          createdProduct: data.name,
        });
      }
    });
  }

  const newPostForm = () => (
    <form className="createProducts__form" onSubmit={clickSubmit}>
      <h4>Post Photo</h4>
      <div className="createProducts__photoData">
        <label className="btn btn-secondary">
          <input
            onChange={handleChange("photo")}
            type="file"
            name="photo"
            accept="image/*"
          />
        </label>
      </div>
      <div className="createProducts__input">
        <TextField
          label="Name"
          type="text"
          onChange={handleChange("name")}
          value={name}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      <div className="createProducts__input createProducts__description">
        <TextField
          label="Description"
          type="text"
          onChange={handleChange("description")}
          value={description}
          autoFocus
          required
          fullWidth
          multiline
          variant="outlined"
        />
      </div>
      <div className="createProducts__input">
        <TextField
          label="Price"
          value={price}
          onChange={handleChange("price")}
          name="numberformat"
          id="formatted-numberformat-input"
          autoFocus
          required
          fullWidth
          variant="standard"
          // InputProps={{
          //     inputComponent: NumberFormatCustom
          // }}
        />
      </div>
      <div className="createProducts__inputDropdown">
        <label className="text-muted">Category</label>
        <select onChange={handleChange("category")} className="form-control">
          <option>{values.category}</option>
          {categories &&
            categories.map((c, i) => (
              <option key={i} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>
      <div className="createProducts__inputDropdown">
        <label className="text-muted">Ingredients</label>
        <select onChange={handleIngredientChange} className="form-control">
          <option>Please select</option>
          {ingredient &&
            ingredient.map((c, i) => (
              <option key={i} value={c._id}>
                {c.name + " " + c.ref}
              </option>
            ))}
        </select>
      </div>
      <div>
        {addedIngredients.map((ingredient2, index) => {
          const ingredientInfo = ingredient.filter(
            (dt) => dt._id === ingredient2
          );
          return (
            <div key={index}>
              <button
                ingredientid={index}
                onClick={(e) => deleteIngredientHandler(e, index)}
              >
                Remove
              </button>
              {ingredientInfo.map((ingredient, index) => {
                return (
                  <div key={index}>
                    <p>{ingredient.name}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="createProducts__input">
        <TextField
          label="Quantity"
          type="number"
          onChange={handleChange("quantity")}
          value={quantity}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      <button className="createProducts__button">Update Product</button>
    </form>
  );

  const showError = () => (
    <div className="" style={{ display: error ? "" : "none" }}>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    </div>
  );

  const showSuccess = () => (
    <div className="" style={{ display: createdProduct ? "" : "none" }}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {`${createdProduct}`} <strong>is created!</strong>
      </Alert>
    </div>
  );

  const showLoading = () =>
    loading && (
      <div className="">
        <Alert severity="info">
          <AlertTitle>Loading...</AlertTitle>
        </Alert>
      </div>
    );

  return (
    <div>
      <div>
        {showLoading()}
        {showSuccess()}
        {showError()}
        {newPostForm()}
      </div>
    </div>
  );
};

export default UpdateProduct;
