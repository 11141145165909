import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import "../../styles/CreateProducts.css";

const ProductHelpers = (props) => {
  // eslint-disable-next-line
  const [values, setValues] = useState({
    loading: false,
    error: "",
    createdProduct: "",
  });
  const [addedIngredients, setAddedIngredients] = useState([]);

  const { loading, error, createdProduct } = values;

  const handleChange = (name) => (event) => {
    const value = name === "photo" ? event.target.files[0] : event.target.value;
    props.formData.set(name, value);
    props.setValues({ ...props.values, [name]: value });
  };

  const handleIngredientChange = (e) => {
    e.preventDefault();
    props.formData.set("ingredients", [...addedIngredients, e.target.value]);
    setAddedIngredients([...addedIngredients, e.target.value]);
  };

  const deleteIngredientHandler = (e, index) => {
    e.preventDefault();
    const myCurrentIngredients = [...addedIngredients];
    myCurrentIngredients.splice(index, 1);
    props.formData.set("ingredients", myCurrentIngredients);
    setAddedIngredients(myCurrentIngredients);
  };

  const newPostForm = () => (
    <form className="createProducts__form" onSubmit={props.clickSubmit}>
      <h4>Post Photo</h4>
      <div className="createProducts__photoData">
        <label className="btn btn-secondary">
          <input
            onChange={handleChange("photo")}
            type="file"
            name="photo"
            accept="image/*"
          />
        </label>
      </div>
      <div className="createProducts__input">
        <TextField
          label="Name"
          type="text"
          onChange={handleChange("name")}
          value={props.name}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      <div className="createProducts__input createProducts__description">
        <TextField
          label="Description"
          type="text"
          onChange={handleChange("description")}
          value={props.description}
          autoFocus
          required
          fullWidth
          multiline
          variant="outlined"
        />
      </div>
      <div className="createProducts__input">
        <TextField
          label="Price"
          type="number"
          onChange={handleChange("price")}
          value={props.price}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      <div className="createProducts__inputDropdown">
        <label className="text-muted">Category</label>
        <select onChange={handleChange("category")} className="form-control">
          <option>Please select</option>
          {props.categories &&
            props.categories.map((c, i) => (
              <option key={i} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>
      <div className="createProducts__inputDropdown">
        <label className="text-muted">Ingredients</label>
        <select onChange={handleIngredientChange} className="form-control">
          <option>Please select</option>
          {props.ingredient &&
            props.ingredient.map((c, i) => (
              <option key={i} value={c._id}>
                {c.name + " " + c.ref}
              </option>
            ))}
        </select>
      </div>
      <div>
        {addedIngredients.map((ingredient2, index) => {
          const ingredientInfo = props.ingredient.filter(
            (dt) => dt._id === ingredient2
          );
          return (
            <div key={index}>
              <button
                ingredientid={index}
                onClick={(e) => deleteIngredientHandler(e, index)}
              >
                Remove
              </button>
              {ingredientInfo.map((ingredient, index) => {
                return (
                  <div key={index}>
                    <p>{ingredient.name}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="createProducts__input">
        <TextField
          label="Quantity"
          type="number"
          onChange={handleChange("quantity")}
          value={props.quantity}
          autoFocus
          required
          fullWidth
          variant="standard"
        />
      </div>
      <button className="createProducts__button">Create Product</button>
    </form>
  );

  const showError = () => (
    <div style={{ display: error ? "" : "none" }}>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    </div>
  );

  const showSuccess = () => (
    <div style={{ display: createdProduct ? "" : "none" }}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {`${createdProduct}`} <strong>is created!</strong>
      </Alert>
    </div>
  );

  const showLoading = () =>
    loading && (
      <div>
        <Alert severity="info">
          <AlertTitle>Loading...</AlertTitle>
        </Alert>
      </div>
    );

  return (
    <div className="createProducts">
      {showLoading()}
      {showSuccess()}
      {showError()}
      {newPostForm()}
    </div>
  );
};

export default ProductHelpers;
