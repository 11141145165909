import {
  ADD_TO_CART_V2,
  SUB_QUANTITY_V2,
  ADD_QUANTITY_V2,
  REMOVE_DISH_V2,
  FETCH_PRODUCTS,
  INIT_DISHES,
  CLEAR_CART,
  FETCH_INGREDIENTS,
  ADD_CURRENT_ORDER,
  CLEAR_CURRENT_ORDER,
} from "../actions/actions";

const initState = {
  total: 0,
  productList: [],
  ingredientList: [],
  specialInstruction: [],
  orderList: [],
  orderTotal: 0,
  cart: [],
  currentOrderHistory: [],
};
const cartReducer = (state = initState, action) => {
  if (action.type === FETCH_PRODUCTS) {
    return {
      ...state,
      productList: state.productList.concat(action.data),
    };
  }

  if (action.type === FETCH_INGREDIENTS) {
    return {
      ...state,
      ingredientList: state.ingredientList.concat(action.data),
    };
  }

  if (action.type === CLEAR_CART) {
    return initState;
  }

  if (action.type === INIT_DISHES) {
    let cart;
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    if (cart) {
      return {
        ...state,
        orderList: cart.orderList,
        orderTotal: cart.orderTotal,
      };
    }

    return state;
  }

  if (action.type === ADD_TO_CART_V2) {
    let totalCurrentOrder = 0;
    const dishesListed = state.productList;
    const currentDishId = action.dishId;
    const currentDish = dishesListed.find((dish) => dish._id === currentDishId);
    totalCurrentOrder = totalCurrentOrder + parseFloat(currentDish.price);

    const ingredients = action.ingredientsList;
    let ingredientsInfo = [];
    if (ingredients.length > 0) {
      ingredientsInfo = ingredients.map((ingredientId) => {
        return state.ingredientList.find((ingredient) => {
          if (ingredient._id === ingredientId) {
            totalCurrentOrder =
              totalCurrentOrder + parseFloat(ingredient.price);
            return 1;
          }
          return 0;
        });
      });
    }

    const specialInstruction = action.specialInstructions;
    let instructionsInfo;
    if (specialInstruction.length > 0) {
      instructionsInfo = specialInstruction.slice(-1)[0];
    }

    const currentOrder = {
      id: action.orderId,
      dish: currentDish,
      quantity: 1,
      ingredients: ingredientsInfo,
      specialInstruction: instructionsInfo,
      total: totalCurrentOrder.toFixed(2),
    };

    if (typeof window !== "undefined") {
      const orderDetails = {
        orderList: [...state.orderList, currentOrder],
        orderTotal: (
          parseFloat(state.orderTotal) + parseFloat(currentOrder.total)
        ).toFixed(2),
      };
      localStorage.setItem("cart", JSON.stringify(orderDetails));
    }

    return {
      ...state,
      orderList: [...state.orderList, currentOrder],
      orderTotal: (
        parseFloat(state.orderTotal) + parseFloat(currentOrder.total)
      ).toFixed(2),
    };
  }

  if (action.type === SUB_QUANTITY_V2) {
    let totalCurrentOrder = 0;
    const currentDishId = action.id;
    const currentOrder = state.orderList;
    const newOrder = [];
    for (let i = 0; i < currentOrder.length; i++) {
      const dish = currentOrder[i];
      if (dish.id === currentDishId) {
        if (dish.quantity <= 1) {
          dish.quantity = 1;
        } else {
          dish.quantity--;
        }
      }
      totalCurrentOrder =
        totalCurrentOrder + parseInt(dish.quantity) * parseFloat(dish.total);
      newOrder.push(dish);
    }

    if (typeof window !== "undefined") {
      const orderDetails = {
        ...state,
        orderList: newOrder,
        orderTotal: totalCurrentOrder,
      };
      localStorage.setItem("cart", JSON.stringify(orderDetails));
    }

    return {
      ...state,
      orderList: newOrder,
      orderTotal: totalCurrentOrder,
    };
  }

  if (action.type === ADD_QUANTITY_V2) {
    let totalCurrentOrder = 0;
    const currentDishId = action.id;
    const currentOrder = state.orderList;
    const newOrder = [];
    for (let i = 0; i < currentOrder.length; i++) {
      const dish = currentOrder[i];
      if (dish.id === currentDishId) {
        dish.quantity++;
      }
      totalCurrentOrder =
        totalCurrentOrder + parseInt(dish.quantity) * parseFloat(dish.total);
      newOrder.push(dish);
    }

    if (typeof window !== "undefined") {
      const orderDetails = {
        ...state,
        orderList: newOrder,
        orderTotal: totalCurrentOrder,
      };
      localStorage.setItem("cart", JSON.stringify(orderDetails));
    }

    return {
      ...state,
      orderList: newOrder,
      orderTotal: totalCurrentOrder,
    };
  }

  if (action.type === REMOVE_DISH_V2) {
    let totalCurrentOrder = 0;
    const currentDishId = action.id;
    const currentOrder = state.orderList;
    const newOrder = [];
    for (let i = 0; i < currentOrder.length; i++) {
      const dish = currentOrder[i];
      if (dish.id !== currentDishId) {
        newOrder.push(dish);
        totalCurrentOrder =
          totalCurrentOrder + parseInt(dish.quantity) * parseFloat(dish.total);
      }
    }

    if (typeof window !== "undefined") {
      const orderDetails = {
        ...state,
        orderList: newOrder,
        orderTotal: totalCurrentOrder,
      };
      localStorage.setItem("cart", JSON.stringify(orderDetails));
    }

    return {
      ...state,
      orderList: newOrder,
      orderTotal: totalCurrentOrder,
    };
  }

  if (action.type === ADD_CURRENT_ORDER) {
    let currentOrderInfo = action.orderInfo;
    return {
      ...state,
      currentOrderHistory: currentOrderInfo
    }
  } 
  
  if (action.type === CLEAR_CURRENT_ORDER) {
    return {
      ...state,
      currentOrderHistory: []
    }
  } else {
    return state;
  }
};

export default cartReducer;
