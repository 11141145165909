import React from "react";
import { useSelector } from "react-redux";
import Card from "../../Card/Card";

function GetMenuProducts(props) {
  const productsList = useSelector((state) => state.productList);
  const filteredProductList = productsList.filter(
    (dt) => dt.category.name === props.productList
  );
  return (
    <div>
      <Card
        url={"product"}
        MenuList={filteredProductList}
        disabled={props.disabled}
      />
    </div>
  );
}

export default GetMenuProducts;
