import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminRoute from "./auth/AdminRoute";
import AppForm from "./components/Applications/English/AppForm";
import AppForma from "./components/Applications/Spanish/AppForma";
import Cart from "./pages/Cart";
import ForgotPassword from "./user/ForgotPassword";
import Home from "./pages/Home";
import Jobs from "./pages/Jobs";
import Kitchen from "./components/Applications/Kitchen";
import Menu from "./pages/Menu";
import Server from "./components/Applications/Server";
import Signin from "./user/Signin";
import {
  fetchIngredients,
  fetchProducts,
  initDishes,
  addCurrentOrder,
} from "./store/actions/actions";
import { getIngredients, getProducts } from "./components/Menu/Api/menuApi";
import { isAuthenticated, getUserInfo } from "./auth/index";
import "./styles/App.css";
import KitchenOrders from "./pages/Admin/KitchenOrders";
import ServerOrders from "./pages/Admin/ServerOrders";
import TodaysOrders from "./pages/Admin/TodaysOrders";
import AdjustMenu from "./pages/Admin/AdjustMenu";
import CreateCategory from "./pages/Admin/CreateCategory";
import CreateProducts from "./pages/Admin/CreateProducts";
import CreateIngredients from "./pages/Admin/CreateIngredients";
import UpdateCategories from "./pages/Admin/UpdateCategories";
import UpdateProduct from "./pages/Admin/UpdateProduct";
import Profile from "./pages/Profile";
import IndividualOrder from "./pages/IndividualOrder";
import Signup from "./user/Signup";
import ResetPassword from "./user/ResetPassword";

var hist = createBrowserHistory();

function App() {
  const dispatch = useDispatch();
  let user = isAuthenticated();
  useEffect(() => {
    dispatch(initDishes());
    getIngredients().then((data) => {
      if (data.error) {
      } else {
        dispatch(fetchIngredients(data));
      }
    });
    getProducts("desc").then((data) => {
      if (data.error) {
      } else {
        dispatch(fetchProducts(data));
      }
    });
    if (user) {
      const userInfo = async () => {
        await getUserInfo(user.user._id, user.token, "0")
          .then((res) => {
            dispatch(addCurrentOrder(res.orders));
          })
          .catch((err) => console.log("err", err));
      };
      userInfo();
    }
  }, [dispatch, user]);
  return (
    <div className="app">
      <BrowserRouter history={hist}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/profile/order/:orderNumber"
            element={<IndividualOrder />}
          />
          <Route
            path="/apply/application/SP/:position"
            element={<AppForma />}
          />
          <Route path="/apply/application/EN/:position" element={<AppForm />} />
          <Route path="/apply/Kitchen" element={<Kitchen />} />
          <Route path="/apply/Server" element={<Server />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          <Route
            path="/admin/dashboard"
            element={
              <AdminRoute>
                <AdminDashboard />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/createCategory"
            element={
              <AdminRoute>
                <CreateCategory />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/createProducts"
            element={
              <AdminRoute>
                <CreateProducts />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/createIngredient"
            element={
              <AdminRoute>
                <CreateIngredients />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/updateCategory"
            element={
              <AdminRoute>
                <UpdateCategories />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/updateProduct/:productId"
            element={
              <AdminRoute>
                <UpdateProduct />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/kitchenOrders"
            element={
              <AdminRoute>
                <KitchenOrders />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/serverOrders"
            element={
              <AdminRoute>
                <ServerOrders />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/todaysOrders"
            element={
              <AdminRoute>
                <TodaysOrders />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/adjustMenu"
            element={
              <AdminRoute>
                <AdjustMenu />
              </AdminRoute>
            }
          />
          {/* <AdminRoute path="/admin/dashboard" element={<AdminDashboard />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
