import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "../../../styles/CheckboxOptions.css";

export default function CheckboxLabels(props) {
  return (
    <FormGroup>
      {props.ingredientCheckboxList.map((ingredient, index) => {
        return (
          <FormControlLabel
            className="checkboxOptions__checkbox"
            key={index}
            control={
              <Checkbox
                checked={ingredient.state}
                onChange={props.addIngredientHandler}
                value={ingredient._id}
              />
            }
            label={[ingredient.name, " $", ingredient.price.toFixed(2)]}
          />
        );
      })}
      <input
        type="text"
        name="specialInstructions"
        onChange={(e) => {props.addSpecialInstructionHandler(e)}}
        value={props.specialInstructions}
        placeholder="Special Instructions"
      />
    </FormGroup>
  );
}
