import React, { useState } from "react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  stripeTokenHandler,
  createOrder,
  emptyCart,
} from "../Menu/Api/menuApi";
import { clearCart } from "../../store/actions/actions";
import { STRIPE_KEY } from "../../config";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(`${STRIPE_KEY}`);

function Stripe(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    loading: false,
    error: "",
  });
  let orderDetails = useSelector((state) => state.orderList);
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#87bbfd" },
        backgroundColor: "#000",
        // height: "200px"
        padding: "11px 15px 11px 0"
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const showLoading = (loading) => loading && <h2>Loading...</h2>;
  const showError = (error) => (
    <div style={{ display: error ? "" : "none" }}>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    </div>
  );

  const CheckoutForm = () => {
    const elements = useElements();
    const stripe = useStripe();
    const createOrderData = {
      products: orderDetails,
      amount: props.totalAmount,
      name: props.fullName,
      phone: props.phoneNumber,
      email: props.emailAddress,
      tipAmount: props.addedTipAmount,
      taxAmount: props.taxAmount,
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      // const { error, paymentMethod } = await stripe.createPaymentMethod({
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      console.log("REsult", result);
      setData({ loading: true });
      if (!result.error) {
        const { id } = result.paymentMethod;
        try {
          await stripeTokenHandler(id, Math.round(props.totalAmount * 100));
          createOrder(props.userId, props.token, createOrderData)
            .then((response) => {
              emptyCart(() => {
                setData({ loading: false });
                props.setSuccess();
                dispatch(clearCart());
              });
            })
            .catch((error) => {
              setData({ loading: false, error: result.error.message });
            });
        } catch (error) {
          if (error.message) {
            setData({ loading: false, error: error.message });
          } else {
            setData({ loading: false, error: error });
          }
        }
      } else {
        if (result.error.message) {
          setData({ loading: false, error: result.error.message });
        }
        console.log("else", result.error);
      }
    };

    return (
      <form className="stripe__form" onSubmit={handleSubmit}>
        <fieldset className="FormGroup">
          <div className="stripe__elements">
            <CardElement options={CARD_OPTIONS} />
          </div>
          <div>
            <button
              className="btn btn-success btn-block"
              type="submit"
              disabled={!props.disabled}
            >
              {!props.disabled ? <p>All fields required</p> : <p>Order Now</p>}
            </button>
          </div>
        </fieldset>
      </form>
    );
  };

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
      {showLoading(data.loading)}
      {showError(data.error)}
    </Elements>
  );
}

export default Stripe;
