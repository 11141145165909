import React, { useState, useEffect, useRef } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { isAuthenticated } from "../../auth/index";
import { Link } from "react-router-dom";
import {
  listOrders,
  getStatusValues,
  sendEmail,
  getReadyTimeValues,
  updateOrderStatus,
  updateReadyTime,
} from "../../components/Menu/Api/menuApi";
import "../../styles/KitchenOrders.css";
import "../../styles/Orders.css";

function ServerOrders() {
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [ordersAmount, setOrdersAmount] = useState(0);
  const [statusValues, setStatusValues] = useState([]);
  const [readyTimeValues, setReadyTimeValues] = useState([]);
  const { user, token } = isAuthenticated();
  
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const loadOrders = () => {
    listOrders(user._id, token, page-1).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setOrdersAmount(data.count);
        setOrders(data.orders);
      }
    });
  };


  function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    loadOrders();
  }, 1000 * 30);

  const loadStatusValues = () => {
    getStatusValues(user._id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setStatusValues(data);
      }
    });
  };

  const loadReadyTimeValues = () => {
    getReadyTimeValues(user._id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setReadyTimeValues(data);
      }
    });
  };

  useEffect(() => {
    loadOrders();
    loadStatusValues();
    loadReadyTimeValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const sendEmailHandler = (orderId) => {
    sendEmail(orderId);
    alert("Email sent");
  };

  const showOrdersLength = () => {
    if (orders.length > 0) {
      return <h1 className="orders__total">Total Orders: {ordersAmount}</h1>;
    } else {
      return <h1 className="orders__total">No orders</h1>;
    }
  };

  const handleStatusChange = (e, orderId, orderUserId) => {
    updateOrderStatus(user._id, token, orderId, e.target.value, orderUserId).then((data) => {
      if (data.error) {
        console.log("status update failed");
      } else {
        loadOrders();
      }
    });
  };

  const handleReadyTimeChange = (e, orderId) => {
    updateReadyTime(user._id, token, orderId, e.target.value).then((data) => {
      if (data.error) {
        console.log("Status update failed");
      } else {
        loadOrders();
      }
    });
  };

  const showStatus = (order) => {
    return (
      <div className="order__status">
        <h3>Status: {order.status}</h3>
        <h3>Ready in: {order.readyTime}</h3>
        <select onChange={(e) => handleStatusChange(e, order._id, order.user._id)}>
          <option>Update Status</option>
          {statusValues.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
        <select onChange={(e) => handleReadyTimeChange(e, order._id)}>
          <option>Update Ready Time</option>
          {readyTimeValues.map((readyTime, index) => (
            <option key={index} value={readyTime}>
              {readyTime}
            </option>
          ))}
        </select>
        <button
          className="orders__button"
          onClick={sendEmailHandler.bind(this, order._id)}
        >
          Send Email
        </button>
      </div>
    );
  };

  const showPagination = () => {
    if (ordersAmount > 9) {
      return (
        <Stack className="orders__paginationStack" spacing={2}>
          <Pagination
            page={page}
            count={Math.ceil((ordersAmount - 1) / 10)}
            color="primary"
            onChange={handleChangePage}
          />
        </Stack>
      );
    } else {
      return "";
    }
  };

  return (
    <div>
      <Link to="/admin/dashboard">
        <button className="orders__backButton">Back</button>
      </Link>
      <h1>{`Hello ${user.name}`}</h1>
      <div className="kitchenOrders">
        <div className="orders">
          {showOrdersLength()}
          {orders.map((order, orderIndex) => {
            return (
              <div
                className="orders__individualOrders"
                key={orderIndex}
                style={{ borderBottom: "5px solid indigo" }}
              >
                <h2>
                  <span>Order #: {ordersAmount - (orderIndex + ((page - 1) * 10))}</span>
                </h2>

                <ul className="order__statusInfo">
                  <li>{showStatus(order)}</li>
                  <li>Username: {order.user.name}</li>
                  <li>Ordered By: {order.name}</li>
                  <li>Phone: {order.phone}</li>
                  <li>Email: {order.email}</li>
                  <li>Amount: ${order.amount}</li>
                  <li>Ordered On: {moment(order.createdAt).fromNow()}</li>
                </ul>
                <h3>Total products in the order: {order.products.length}</h3>

                {order.products.map((product, productIndex) => {
                  let mealName = product.dish.map((meal) => {
                    return meal.name;
                  });
                  let ingredientName = product.ingredients.map((ingredient) => {
                    return ingredient.name;
                  });
                  return (
                    <div
                      key={productIndex}
                      style={{ padding: "20px", border: "5px solid indigo" }}
                    >
                      <p style={{ fontWeight: "bold" }}>
                        {product.quantity} {mealName}{" "}
                        <span style={{ color: "red" }}>
                          {product.specialInstruction}
                        </span>{" "}
                        <span style={{ color: "green" }}>
                          {ingredientName.join(", ")}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {showPagination()}
    </div>
  );
}

export default ServerOrders;
