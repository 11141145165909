import React from "react";
import GetMenuProducts from "./GetMenuProducts";
import "../../../styles/MenuProducts.css";

function Kids() {
  return (
    <div className="menu__categories">
      <div>
        <h3>Kids</h3>
        <GetMenuProducts productList={"KidsV2"} />
      </div>
    </div>
  );
}

export default Kids;
