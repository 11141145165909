export const ADD_TO_CART_V2 = "ADD_TO_CART_V2";
export const SUB_QUANTITY_V2 = "SUB_QUANTITY_V2";
export const ADD_QUANTITY_V2 = "ADD_QUANTITY_V2";
export const REMOVE_DISH_V2 = "REMOVE_DISH_V2";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const INIT_DISHES = "INIT_DISHES";
export const CLEAR_CART = "CLEAR_CART";
export const FETCH_INGREDIENTS = "FETCH_INGREDIENTS";
export const ADD_CURRENT_ORDER = "ADD_CURRENT_ORDER";
export const CLEAR_CURRENT_ORDER = "CLEAR_CURRENT_ORDER";

export const addToCartV2 = (
  dishId,
  // productList,
  ingredientsList,
  specialInstructions = []
) => {
  return {
    type: ADD_TO_CART_V2,
    orderId: String(dishId) + String(Math.floor(Math.random() * 1000)),
    dishId,
    // productList,
    ingredientsList,
    specialInstructions,
  };
};

export const subtractQuantityV2 = (id) => {
  return {
    type: SUB_QUANTITY_V2,
    id,
  };
};

export const addQuantityV2 = (id) => {
  return {
    type: ADD_QUANTITY_V2,
    id,
  };
};

export const removeDishV2 = (id) => {
  return {
    type: REMOVE_DISH_V2,
    id,
  };
};

export const fetchProducts = (data) => {
  return {
    type: FETCH_PRODUCTS,
    data,
  };
};

export const initDishes = () => {
  return {
    type: INIT_DISHES,
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};

export const fetchIngredients = (data) => {
  return {
    type: FETCH_INGREDIENTS,
    data,
  };
};

export const addCurrentOrder = (orderInfo) => {
  return {
    type: ADD_CURRENT_ORDER,
    orderInfo,
  };
};

export const clearCurrentOrder = () => {
  return {
    type: CLEAR_CURRENT_ORDER,
  };
};
