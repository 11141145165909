import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "./index";

const AdminRoute = ({ children }) => {
  return isAuthenticated()?.user.role === 1 ? (
    children
  ) : (
    <Navigate to="/signin" replace />
  );
};

export default AdminRoute;