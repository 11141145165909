import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import RibbonCut from "../assets/img/ribbonCutting.png";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      <Header />
      <div className="home__mainImage"></div>
      <div className="home__imageText">
        <h1>Feel The Taste of Origin</h1>
      </div>
      <div className="home__mainText">
        <p>
          Taqueria Las Cumbres, Crystal Lake's Authentic Mexican Restaurant.
          Where you get the cozy and family feel. Our Mexican food is fresh,
          which allows us to customize it how you want it.
        </p>
        <img src={RibbonCut} alt="" className="home__ribbonPic" />
        <p>
          Las Cumbres is a town from Maltrata, Veracruz. It is known for having
          steep, dangerous, yet beautiful mountains. The name of the restaurant
          originated from this town, where I was raised. Our dishes consist of a
          mixture of authentic Mexican Food, originating from Puebla and
          Veracruz. We focus on the authenticity of the recipe by purchasing the
          ingredients directly from Mexico.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
