import React from 'react';
import ScrollableTabs from "./ScrollableTabs";
import "../../styles/TabNames.css"

function MenuOptions() {
    const tabNames = ["A La Carte", "Breakfast", "Burgers", "Drinks", "Kids", "Main Dishes", "Seafood", "Sides", "Soups", "Specials"]
    return (
        <div className="tabNames">
            <ScrollableTabs tabNames={tabNames} />
        </div>
    )
}

export default MenuOptions
