import React, { useState } from "react";
import Header from "../../Header/Header";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { submitApplication } from "../../Menu/Api/menuApi";
import "../../../styles/Form.css"

function AppForm() {
  const { position } = useParams();
  // eslint-disable-next-line
  const [data, setData] = useState({
    loading: false,
    success: false,
    error: "",
    firstName: "",
    lastName: "",
    birth: "",
    phone: "",
    email: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    skills: "",
    companyOne: "",
    companyTwo: "",
    companyThree: "",
    positionOne: "",
    positionTwo: "",
    positionThree: "",
    yearsOne: "",
    yearsTwo: "",
    yearsThree: "",
  });

  const handleFirstName = (event) => {
    setData({ ...data, firstName: event.target.value });
  };

  const handleLastName = (event) => {
    setData({ ...data, lastName: event.target.value });
  };

  const handleBirth = (event) => {
    setData({ ...data, birth: event.target.value });
  };

  const handlePhone = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleEmail = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handleAddress = (event) => {
    setData({ ...data, address: event.target.value });
  };

  const handleAddress2 = (event) => {
    setData({ ...data, address2: event.target.value });
  };

  const handleCity = (event) => {
    setData({ ...data, city: event.target.value });
  };

  const handleState = (event) => {
    setData({ ...data, state: event.target.value });
  };

  const handleZip = (event) => {
    setData({ ...data, zip: event.target.value });
  };

  const handleSkills = (event) => {
    setData({ ...data, skills: event.target.value });
  };

  const handleCompanyOne = (event) => {
    setData({ ...data, companyOne: event.target.value });
  };

  const handleCompanyTwo = (event) => {
    setData({ ...data, companyTwo: event.target.value });
  };

  const handleCompanyThree = (event) => {
    setData({ ...data, companyThree: event.target.value });
  };

  const handlePositionOne = (event) => {
    setData({ ...data, positionOne: event.target.value });
  };

  const handlePositionTwo = (event) => {
    setData({ ...data, positionTwo: event.target.value });
  };

  const handlePositionThree = (event) => {
    setData({ ...data, positionThree: event.target.value });
  };

  const handleYearsOne = (event) => {
    setData({ ...data, yearsOne: event.target.value });
  };

  const handleYearsTwo = (event) => {
    setData({ ...data, yearsTwo: event.target.value });
  };

  const handleYearsThree = (event) => {
    setData({ ...data, yearsThree: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const personDetails = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      birthDate: data.birth,
      address: data.address,
      address2: data.address2,
      city: data.city,
      zipCode: data.zip,
      state: data.state,
      position: position,
      skills: data.skills,
      companyOne: data.companyOne,
      companyTwo: data.companyTwo,
      companyThree: data.companyThree,
      positionOne: data.positionOne,
      positionTwo: data.positionTwo,
      positionThree: data.positionThree,
      yearsOne: data.yearsOne,
      yearsTwo: data.yearsTwo,
      yearsThree: data.yearsThree,
    };
    submitApplication(personDetails);
    alert("Thank you, your application has been submitted");
  };

  return (
    <div>
      <Header />
      <form onSubmit={handleSubmit}  className="form">
        <div>
          <h4>Personal Information:</h4>
        </div>
        <div>
          <TextField
            label="First Name"
            type="text"
            // onChange={handleTip}
            onChange={handleFirstName}
            value={data.firstName}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Last Name"
            type="text"
            // onChange={handleTip}
            onChange={handleLastName}
            value={data.lastName}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Phone Number"
            type="tel"
            // onChange={handleTip}
            onChange={handlePhone}
            value={data.phone}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Email"
            type="email"
            // onChange={handleTip}
            onChange={handleEmail}
            value={data.email}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Birth Date"
            type="text"
            // onChange={handleTip}
            placeholder="MM/DD/YYYY"
            value={data.birth}
            onChange={handleBirth}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Address"
            type="text"
            // onChange={handleTip}
            value={data.address}
            onChange={handleAddress}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Address 2"
            type="text"
            // onChange={handleTip}
            value={data.address2}
            onChange={handleAddress2}
            autoFocus
            // required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="City"
            type="text"
            // onChange={handleTip}
            value={data.city}
            onChange={handleCity}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="State"
            type="text"
            // onChange={handleTip}
            value={data.state}
            onChange={handleState}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Zip"
            type="text"
            // onChange={handleTip}
            onChange={handleZip}
            value={data.zip}
            autoFocus
            required
            fullWidth
            variant="standard"
          />
        </div>
        <div>
          <h4 className="formSection">Skills and Abilities:</h4>
          <TextField
            label="List of Skills and abilities:"
            type="text"
            // onChange={handleChange("description")}
            value={data.skills}
            onChange={handleSkills}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
          />
        </div>
        <div className="formSection">
          <h4>Work Experience</h4>
        </div>
        <div>
          <TextField
            label="Company Name"
            type="text"
            // onChange={handleChange("description")}
            value={data.companyOne}
            onChange={handleCompanyOne}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
          <TextField
            label="Position"
            type="text"
            // onChange={handleChange("description")}
            value={data.positionOne}
            onChange={handlePositionOne}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
          <TextField
            label="Number of years:"
            type="number"
            // onChange={handleChange("description")}
            value={data.yearsOne}
            onChange={handleYearsOne}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
        </div>
        <div>
          <TextField
            label="Company Name"
            type="text"
            // onChange={handleChange("description")}
            value={data.companyTwo}
            onChange={handleCompanyTwo}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
          <TextField
            label="Position"
            type="text"
            // onChange={handleChange("description")}
            value={data.positionTwo}
            onChange={handlePositionTwo}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
          <TextField
            label="Number of years:"
            type="number"
            // onChange={handleChange("description")}
            value={data.yearsTwo}
            onChange={handleYearsTwo}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
        </div>
        <div>
          <TextField
            label="Company Name"
            type="text"
            // onChange={handleChange("description")}
            value={data.companyThree}
            onChange={handleCompanyThree}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
          <TextField
            label="Position"
            type="text"
            // onChange={handleChange("description")}
            value={data.positionThree}
            onChange={handlePositionThree}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
          <TextField
            label="Number of years:"
            type="number"
            // onChange={handleChange("description")}
            value={data.yearsThree}
            onChange={handleYearsThree}
            autoFocus
            // required
            fullWidth
            multiline
            variant="outlined"
            className="formSection"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default AppForm;
