import React from "react";
import GetMenuProducts from "./GetMenuProducts";
import "../../../styles/MenuProducts.css";

function Seafood() {
  return (
    <div className="menu__categories">
      <div>
        <h3>Seafood & Vegetarian</h3>
        <GetMenuProducts productList={"SeafoodV2"} />
      </div>
    </div>
  );
}

export default Seafood;
